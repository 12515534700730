import {
  Button,
  Checkbox,
  FormField,
  Grid,
  Multiselect,
  Select,
  SelectProps,
  Toggle
} from '@amzn/awsui-components-react';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ExternalDashboard } from '../../../Domain/Cloudwatch/ExternalDashboard';
import {
  CloudwatchMetrics,
  ExternalWidget,
  MetricDimension,
} from '../../../Domain/Cloudwatch/model';
import { CommonDomainProps } from '../../../models/types';
import { getDomainIdentifier } from '../../../utils/helpers';
import './../../../Styles/cloudwatch-dasboard.css';
import { useListCwMetrics } from './hooks/useListCwMetrics';
import { getExplorerMetrics, getMetricsOptions } from './utils';
import { CloudWatch, CloudwatchWidget } from '../../../Domain/Cloudwatch/CloudWatch';
import { useListCwRootMetrics } from "./hooks/useListCwRootMetrics";
import { isError } from "../../../ErrorHandler/apiErrorHandler";
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList } from "../../../ErrorHandler/utils";

const statsOptions: SelectProps.Options = [
  { label: 'Maximum', value: 'Maximum' },
  { label: 'Minimum', value: 'Minimum' },
  { label: 'Average', value: 'Average' },
  { label: 'Sum', value: 'Sum' },
];

interface ExplorerForm {
  selectedMetric: SelectProps.Option;
  nodeIds: SelectProps.Options;
  stats: SelectProps.Options;
  splitByNodeId: boolean;
  isDirty: boolean;
}

interface CloudwatchDashboardProps extends CommonDomainProps {
  clientId: string;
  domainName: string;
  rootAccountId: string;
  cellId: string;
  metricRange: any;
  region: string;
  isOptInRegion: boolean;
  initialValues?: ExplorerForm;
  utcOffset: number;
}

const getNodeIds = (dimensions: MetricDimension[][]) => {
  return dimensions
    .map((dimension) => dimension.find((dim) => dim.name === 'NodeId')?.value)
    .filter(Boolean)
    .map((nodeId) => ({ label: nodeId, value: nodeId }));
};

const Explorer = (props: CloudwatchDashboardProps) => {
  let rootMetrics: CloudwatchMetrics
  let selectedMetricDimensions

  const { loading, data, error } = useListCwMetrics({
    domainIdentifier: props.domainIdentifier,
  });

  const {listRootCwLoading, data: listRootCwData, error: listRootCwError} =
      useListCwRootMetrics({
          domainIdentifier: getDomainIdentifier(
                props.clientId,
                props.domainName
            ),
          accountId: props.cellId
        });

  const [listRootMetrics, setListRootMetrics] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

 const isErrorPresent = isError(error)
 const isListRootCwErrorPresent = isError(listRootCwError)
 useEffect(() => {
     if ( isErrorPresent || isListRootCwErrorPresent) {
       setErrorMessage("Unable to get Cloudwatch Metrics. Please refresh or try again later");
    }
  }, [isErrorPresent, error, isListRootCwErrorPresent, listRootCwError]);


 rootMetrics = get(
     listRootCwData,
     'listRootMetrics.metrics',
     undefined
 );

  const metrics: CloudwatchMetrics = get(
      data,
      'listMetrics.metrics',
      undefined
  );
  const initialValues = props.initialValues
    ? props.initialValues
    : {
        selectedMetric: { label: '', value: '' },
        nodeIds: [],
        stats: [{ label: 'Average', value: 'Average' }],
        splitByNodeId: false,
        isDirty: false,
      };
  const [formValues, setFormValues] = useState<ExplorerForm>(initialValues);

  const [externalWidgets, setExternalWidgets] = useState<ExternalWidget[]>([]);
  const [cwWidgets, setCwWidgets] = useState<CloudwatchWidget[]>([]);
  if (listRootMetrics) {
      selectedMetricDimensions = rootMetrics ? rootMetrics[formValues.selectedMetric.label] || [] : [];
  } else {
      selectedMetricDimensions = metrics
    ? metrics[formValues.selectedMetric.label] || []
    : [];
  }
  const nodeIdsOptions = getNodeIds(selectedMetricDimensions);
  const isFormInvalid = () => {
    return (
      formValues.stats.length === 0 ||
      !formValues.selectedMetric ||
      formValues.selectedMetric.label === ''
    );
  };

  const handleSubmit = useCallback(() => {
    setFormValues((prevFormValues: ExplorerForm) => ({
      ...prevFormValues,
      isDirty: false,
    }));
    let isRootAccount = false;
    const stats = formValues.stats
      ? formValues.stats.map((stat: SelectProps.Option) =>
          stat ? stat.label : ''
        )
      : ['Average'];
    const metricName = formValues.selectedMetric
      ? formValues.selectedMetric.label
      : '';
    const nodeIds = formValues.nodeIds ? formValues.nodeIds.map((nodeId: SelectProps.Option) =>
      nodeId ? nodeId.label : ''
    ) : nodeIdsOptions.map((option) => option.label)
    if(listRootMetrics) {
        isRootAccount = true
    }
    if (isRootAccount) {
        const updatedWidgets = getExplorerMetrics(
          props.clientId,
          props.rootAccountId,
          metricName,
          stats,
          nodeIds,
          selectedMetricDimensions,
          isRootAccount
        ) as CloudwatchWidget[];
        setCwWidgets(updatedWidgets);
        setExternalWidgets([]);
    } else {
        const updatedWidgets = getExplorerMetrics(
          props.clientId,
          props.rootAccountId,
          metricName,
          stats,
          nodeIds,
          selectedMetricDimensions,
          isRootAccount
        ) as ExternalWidget[];
        setExternalWidgets(updatedWidgets);
        setCwWidgets([]);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, selectedMetricDimensions]);

  // When it is initial autosubmit
  useEffect(() => {
    if (props.initialValues) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, props.initialValues]);

  return (
      <React.Fragment>
        {errorMessage ? (
            <ErrorMessageComponent  errorMessage={errorMessage} apiName={ApiList.EXPLORER_CW_METRICS}/>
        ):(
      <Grid
        className="dasboard-explorer"
        gridDefinition={[
          { colspan: 1 },
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 2 },
          { colspan: 1 },
        ]}
      >
          <Toggle
            onChange={({ detail }) => {
              setListRootMetrics(detail.checked)
              setFormValues( {
        selectedMetric: { label: '', value: '' },
        nodeIds: [],
        stats: [{ label: 'Average', value: 'Average' }],
        splitByNodeId: false,
        isDirty: false,
      })
            }
          }
            checked={listRootMetrics}
          >
            List Root Metrics
          </Toggle>
        <FormField description="Choose metric from the list." label="Metric">
          <Select
            selectedOption={formValues.selectedMetric}
            onChange={({ detail }) =>
              setFormValues((prevFormValues: ExplorerForm) => ({
                ...prevFormValues,
                selectedMetric: detail.selectedOption,
                isDirty: true,
              }))
            }
            options={listRootMetrics ? getMetricsOptions(rootMetrics) : getMetricsOptions(metrics)}
            filteringType="auto"
            selectedAriaLabel="Selected"
          />
        </FormField>
        <FormField description="Choose stats for the metric." label="Stats">
          <Multiselect
            selectedOptions={formValues.stats}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={statsOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: ExplorerForm) => ({
                ...prevFormValues,
                stats: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <FormField
          description="Choose Node IDs to look for metrics."
          label="Node ID"
        >
          <Multiselect
            disabled={nodeIdsOptions.length === 0}
            invalid={formValues.stats.length === 0}
            selectedOptions={formValues.nodeIds}
            deselectAriaLabel={(e) => 'Remove ' + e.label}
            options={nodeIdsOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
            onChange={({ detail }) =>
              setFormValues((prevFormValues: ExplorerForm) => ({
                ...prevFormValues,
                nodeIds: detail.selectedOptions,
                isDirty: true,
              }))
            }
          />
        </FormField>
        <Button
          className="explorer-button-checkbox"
          variant="primary"
          disabled={isFormInvalid()}
          onClick={handleSubmit}
        >
          Fetch
        </Button>
      </Grid>
        )}
      {externalWidgets && externalWidgets.length > 0 ? (
        <ExternalDashboard
          utcOffset={props.utcOffset}
          widgets={externalWidgets}
          domainIdentifier={getDomainIdentifier(
            props.clientId,
            props.domainName
          )}
          metricRange={props.metricRange}
          generateShareParams={() =>
            `title=custom&metricName=${
              formValues.selectedMetric[0].label
            }&stats=${formValues.stats
              .map((stat) => stat.label)
              .join(',')}&nodeIds=${formValues.nodeIds
              .map((nodeId) => nodeId.label)
              .join(',')}&splitByResource=${formValues.splitByNodeId}`
          }
        />
      ) : null}
      {cwWidgets && cwWidgets.length > 0 ? (
        <CloudWatch
          region={props.region}
          rootAccountId={props.rootAccountId}
          isOptInRegion={props.isOptInRegion}
          widgets={cwWidgets}
        />
      ) : null}
      
    </React.Fragment>
  );
};

export { Explorer };
