import { DateRangePickerProps } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { CloudwatchWidget } from '../../../Domain/Cloudwatch/CloudWatch';
import {
  ExternalWidget,
  MetricDimension,
} from '../../../Domain/Cloudwatch/model';
import {
  ALB_HOST_METRICS,
  ALB_METRICS,
  BEAGLEPOUND_DASHBOARD_OBJ,
  BEAGLEPOUND_EP,
  BEAGLEPOUND_OBJ,
  EC2_METRICS_DIMENSIONS,
  ES_CARBON_METRICS,
  ES_METRICS,
  KRAKEN_METRICS,
  PA_METRICS,
  MetricDefinitions,
  GraphDefinition,
  HA_PROXY_METRIC_DIMENSIONS,
  S3_DISCOVERY_METRICS,
  DRAGONSTONE_METRICS, INDEXING_METRICS,
  PROCESS_METRIC_DIMENSIONS,
} from './constants';

export const getCommonRanges = () => [
  { start: 'now-1h', end: 'now', label: '1 hour' },
  { start: 'now-3h', end: 'now', label: '3 hours' },
  { start: 'now-12h', end: 'now', label: '12 hours' },
  { start: 'now-1d', end: 'now', label: '24 hours' },
  { start: 'now-3d', end: 'now', label: '3 days' },
  { start: 'now-1w', end: 'now', label: '1 week' },
  { start: 'now-2w', end: 'now', label: '2 weeks' },
  { start: 'now-1M', end: 'now', label: '1 month' },
  { start: 'now-3M', end: 'now', label: '3 months' },
];
export const getPeriod = (diffDays: number) => {
  let period = 300;
  switch (true) {
    case diffDays <= 30:
      period = 300; //5 mins data points
      break;
    case diffDays <= 60:
      period = 180; // half an hour data points
      break;
    case diffDays <= 90:
      period = 3600; // 1 hour data points
      break;
    case diffDays <= 120:
      period = 21600; // 6 hour data points
      break;
    case diffDays <= 180:
      period = 43200; // 12 hour data points
      break;
    default:
      period = 86400; // 1 day data;
      break;
  }
  return period;
};

export const getMetricsOptions = (metrics: any) => {
  if (metrics) {
    let metricOptions = Object.keys(metrics).map((metrc) => ({
      label: metrc,
      value: metrc,
    }));
    return metricOptions.sort((a, b) =>
      a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    );
  }
  return [{ label: '', value: '' }];
};

export const getExplorerMetrics = (
  clientId: string,
  rootAccountId: string,
  metricName: string,
  stats: string[],
  nodeIds: string[],
  dimensions: MetricDimension[][],
  isRootAccount: boolean = false,
): ExternalWidget[] | CloudwatchWidget[] => {
  const AD_HOC_METRIC_DEF: MetricDefinitions = {
      dimensionNames: {},
      namespace: 'AWS/ES',
      graphs: [
        {
          title: `${metricName}`,
          paramSets: [{ stats: stats, metricNames: [metricName], isRootAccount: isRootAccount }],
        },
      ],
    };
  const dimensionChunks = chunkArray(dimensions, 200);
  if (isRootAccount){
    return buildMetricsForChunks(
      AD_HOC_METRIC_DEF,
      dimensionChunks,
      clientId,
      rootAccountId,
        true
    ) as CloudwatchWidget[];
  }
  else {
    return buildMetricsForChunks(
      AD_HOC_METRIC_DEF,
      dimensionChunks,
      clientId,
      rootAccountId,
        false
    ) as ExternalWidget[];
  }
};

export const getESCarbonMetrics = (
  domainName: string,
  clientId: string,
  rootAccountId: string,
  region: string,
  cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const carbonDimensions = [
    [
      {
        name: ES_CARBON_METRICS.dimensionNames.CLIENT_ID,
        value: clientId,
      },
      {
        name: ES_CARBON_METRICS.dimensionNames.DOMAIN_NAME,
        value: domainName,
      },
      {
        name: ES_CARBON_METRICS.dimensionNames.ACTIVE_AZ,
	value: "0",
      },
    ],
    [
      {
        name: ES_CARBON_METRICS.dimensionNames.CLIENT_ID,
	value: clientId,
      },
      {
        name: ES_CARBON_METRICS.dimensionNames.DOMAIN_NAME,
	value: domainName,
      },
      {
        name: ES_CARBON_METRICS.dimensionNames.ACTIVE_AZ,
	value: "1",
      },
    ]
  ];

  return cwDef
    ? buildWidgets(
        ES_CARBON_METRICS,
        carbonDimensions,
        region,
        clientId,
        rootAccountId
      ).filter(
        (widget: CloudwatchWidget) => widget.properties.metrics.length > 0
      )
    : buildExternalWidgets(
        ES_CARBON_METRICS,
        carbonDimensions,
        clientId,
        rootAccountId
      ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
};

const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const getPaMetrics = (
  domainName: string,
  clientId: string,
  rootAccountId: string,
  region: string,
): CloudwatchWidget[] =>{
  const dimensions = [
    [
      {
        name: PA_METRICS.dimensionNames.CLIENT_ID,
        value: clientId,
      },
      {
        name: PA_METRICS.dimensionNames.DOMAIN_NAME,
        value: domainName,
      },
      {
        name: PA_METRICS.dimensionNames.SERVICE_NAME,
        value: "PerformanceAnalyzerAgent",
      },
      {
        name: PA_METRICS.dimensionNames.AGGR,
        value: "MAX",
      },
      {
        name: PA_METRICS.dimensionNames.RCA_VERSION,
        value: "0.0.1",
      },
    ]
  ]

  return buildWidgets(
        PA_METRICS,
        dimensions,
        region,
        clientId,
        rootAccountId
      ).filter(
        (widget: CloudwatchWidget) => widget.properties.metrics.length > 0
      )
}

export const getElasticsearchMetrics = (
  domainName: string,
  clientId: string,
  rootAccountId: string,
  region: string,
  cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const dimensions = [
    [
      {
        name: ES_METRICS.dimensionNames.CLIENT_ID,
        value: clientId,
      },
      {
        name: ES_METRICS.dimensionNames.DOMAIN_NAME,
        value: domainName,
      },
    ],
  ];
  // For now omit all customer metrics from CW.
  return cwDef
    ? buildWidgets(
        ES_METRICS,
        dimensions,
        region,
        clientId,
        rootAccountId
      ).filter(
        (widget: CloudwatchWidget) => widget.properties.metrics.length > 0
      )
    : buildExternalWidgets(
        ES_METRICS,
        dimensions,
        clientId,
        rootAccountId
      ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
};

export const getIndexingMetrics = (
  domainName: string,
  clientId: string,
  rootAccountId: string,
): ExternalWidget[] => {
  const dimensions = [
    [
      {
        name: INDEXING_METRICS.dimensionNames.CLIENT_ID,
        value: clientId,
      },
      {
        name: INDEXING_METRICS.dimensionNames.DOMAIN_NAME,
        value: domainName,
      }
    ]
  ]

  return buildExternalWidgets(
    INDEXING_METRICS,
    dimensions,
    clientId,
    rootAccountId
  ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
}

export const getLoadBalancersMetrics = (
  domainName: string,
  clientId: string,
  lb: string,
  tg: string,
  rootAccountId: string,
  region: string,
  cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const dimensions = [
    [
      {
        name: ALB_METRICS.dimensionNames.LOAD_BALANCER,
        value: lb,
      },
    ],
  ];
  const hostDimensions = [
    [
      {
        name: ALB_HOST_METRICS.dimensionNames.LOAD_BALANCER,
        value: lb,
      },
      {
        name: ALB_HOST_METRICS.dimensionNames.TARGET_GROUP,
        value: tg,
      },
    ],
  ];
  if (cwDef) {
    const albHostMetrics = buildWidgets(
      ALB_HOST_METRICS,
      hostDimensions,
      region,
      clientId,
      rootAccountId
    );
    const albMetrics = buildWidgets(
      ALB_METRICS,
      dimensions,
      region,
      clientId,
      rootAccountId
    );
    return [...albHostMetrics, ...albMetrics];
  } else {
    const albHostMetrics = buildExternalWidgets(
      ALB_HOST_METRICS,
      hostDimensions,
      clientId,
      rootAccountId
    );
    const albMetrics = buildExternalWidgets(
      ALB_METRICS,
      dimensions,
      clientId,
      rootAccountId
    );
    return [...albHostMetrics, ...albMetrics];
  }
};

export const getElasticsearchKrakenMetrics = (
  domainName: string,
  clientId: string,
  rootAccountId: string,
  region: string,
  cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const dimensions = [
    [
      {
        name: ES_METRICS.dimensionNames.CLIENT_ID,
        value: clientId,
      },
      {
        name: ES_METRICS.dimensionNames.DOMAIN_NAME,
        value: domainName,
      },
    ],
  ];
  if (cwDef) {
    return buildWidgets(
      KRAKEN_METRICS,
      dimensions,
      region,
      clientId,
      rootAccountId
    ).filter(
      (widget: CloudwatchWidget) => widget.properties.metrics.length > 0
    );
  } else {
    return buildExternalWidgets(
      KRAKEN_METRICS,
      dimensions,
      clientId,
      rootAccountId
    ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
  }
};

type MetricWidget = CloudwatchWidget | ExternalWidget;

const buildMetricsForChunks = (
  cwMetricsSet: MetricDefinitions,
  dimensionChunks: MetricDimension[][][],
  clientId: string,
  rootAccountId: string,
  isCloudwatch: boolean,
  region?: string// Flag to decide the widget type
): CloudwatchWidget[] | ExternalWidget[] => {
  if (isCloudwatch) {
    return cwMetricsSet.graphs.flatMap(graph =>
    dimensionChunks.flatMap(chunk =>
      buildWidgets({ ...cwMetricsSet, graphs: [graph] }, chunk, region, clientId, rootAccountId)
    )
  );
  } else {
    return cwMetricsSet.graphs.flatMap(graph =>
    dimensionChunks.flatMap(chunk =>
     buildExternalWidgets({ ...cwMetricsSet, graphs: [graph] }, chunk, clientId, rootAccountId)
    )
    );
  };
};


enum EBS_METRICS_DIMENSIONS {
  VOLUME_ID = 'VolumeId',
}

enum EBS_ES_METRICS_DIMENSIONS {
    INSTANCE_ID = 'InstanceId',
    CLIENT_ID = 'ClientId',
    DOMAIN_NAME = 'DomainName',
}

export const getEBSMetrics = (
  domainName: string,
  clientId: string,
  instancesIds: string[],
  rootAccountId: string,
  region: string,
  stats: string[],
  cwDef: boolean = true,
  metric: string[]
): MetricWidget[] => {
  const allGraphs = [
    {
        title: 'ThroughputMicroBursting',
        paramSets: [
          {
            stats: stats,
            metricNames: ['ThroughputMicroBursting'],
            isRootAccount: true,
          },
        ],
    },
    {
        title: 'IOPSMicroBursting',
        paramSets: [
          {
            stats: stats,
            metricNames: ['IOPSMicroBursting'],
            isRootAccount: true,
          },
        ],
    }
  ];

  // Filter graphs based on selected metric names
  const graphs = metric && metric.length > 0
    ? allGraphs.filter(graph => metric.includes(graph.paramSets[0].metricNames[0]))
    : allGraphs;

  const EC2_METRICS: MetricDefinitions = {
    namespace: 'AWS/EC2',
    dimensionNames: EBS_ES_METRICS_DIMENSIONS,
    graphs
  };
  const dimensions = instancesIds.map((instancesId: string) => [
    {
      name: EC2_METRICS.dimensionNames.INSTANCE_ID,
      value: instancesId,
    },
    {
      name: EC2_METRICS.dimensionNames.CLIENT_ID,
      value: clientId,
    },
    {
      name: EC2_METRICS.dimensionNames.DOMAIN_NAME,
      value: domainName,
    },
  ]);

  const dimensionChunks = chunkArray(dimensions, 400);

  return cwDef
    ? [
        ...buildMetricsForChunks(EC2_METRICS, dimensionChunks, clientId, rootAccountId, true, region),
      ]
    : [
        ...buildMetricsForChunks(EC2_METRICS, dimensionChunks, clientId, rootAccountId, false, region),
      ];
};

export const getEBSVolumeMetrics = (
  domainName: string,
  clientId: string,
  rootAccountId: string,
  region: string,
  volumeIds: string[],
  stats: string[],
  cwDef: boolean = true,
): MetricWidget[] => {

  const EBS_VOLUME_METRICS: MetricDefinitions = {
    namespace: 'AWS/ES',
    dimensionNames: EBS_METRICS_DIMENSIONS,
    graphs: [
      {
        title: 'EBS_Burst_Balance',
        paramSets: [
          {
            stats: stats,
            metricNames: ['BurstBalance'],
          },
        ],
      },
    ],
  };

  const dimensions_ebs = volumeIds.map((volumeId: string) => [
    {
      name: EBS_VOLUME_METRICS.dimensionNames.VOLUME_ID,
      value: volumeId,
    },
  ]);

  const dimensionChunksEBS = chunkArray(dimensions_ebs, 400);

  return cwDef
    ? [
        ...buildMetricsForChunks(EBS_VOLUME_METRICS, dimensionChunksEBS, clientId, rootAccountId, true, region),
      ]
    : [
        ...buildMetricsForChunks(EBS_VOLUME_METRICS, dimensionChunksEBS, clientId, rootAccountId, false, region),
      ];
};

export const getEC2Metrics = (
  domainName: string,
  clientId: string,
  instanceIds: string[],
  rootAccountId: string,
  region: string,
  stats: string[],
  cwDef: boolean,
  selectedMetricNames?: string[]
): MetricWidget[] => {
  const allGraphs = [
    {
      title: 'EC2_Instance_CPU',
      paramSets: [{ stats, metricNames: ['CPUUtilization'], unit: 'Percent' }],
    },
    {
      title: 'EC2_Instance_Network_In',
      paramSets: [{ stats, metricNames: ['NetworkIn'], unit: 'Bytes' }],
    },
    {
      title: 'EC2_Instance_Network_Out',
      paramSets: [{ stats, metricNames: ['NetworkOut'], unit: 'Bytes' }],
    },
    {
      title: 'EC2_Instance_Disk_Writes',
      paramSets: [{ stats, metricNames: ['DiskWriteBytes'], unit: 'Bytes' }],
    }
  ];

  // Filter graphs based on selected metric names
  const graphs = selectedMetricNames && selectedMetricNames.length > 0
    ? allGraphs.filter(graph => selectedMetricNames.includes(graph.paramSets[0].metricNames[0]))
    : allGraphs;

  const EC2_METRICS: MetricDefinitions = {
    namespace: 'AWS/EC2',
    dimensionNames: EC2_METRICS_DIMENSIONS,
    graphs
  };
  const dimensions = instanceIds.map((id: string) => [
    { name: EC2_METRICS.dimensionNames.INSTANCE_ID, value: id },
  ]);

  const dimensionChunks = chunkArray(dimensions, 400);
  return cwDef
    ? [
        ...buildMetricsForChunks(EC2_METRICS, dimensionChunks, clientId, rootAccountId, true, region),
      ]
    : [
        ...buildMetricsForChunks(EC2_METRICS, dimensionChunks, clientId, rootAccountId, false, region),
      ];
};

export const getS3DiscoveryMetrics = (
  domainName: string,
  clientId: string,
  rootAccountId: string,
  region: string,
  cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const dimensions = [
    [
      {
        name: ES_METRICS.dimensionNames.CLIENT_ID,
        value: clientId,
      },
      {
        name: ES_METRICS.dimensionNames.DOMAIN_NAME,
        value: domainName,
      },
    ],
  ];
  if (cwDef) {
    return buildWidgets(
      S3_DISCOVERY_METRICS,
      dimensions,
      region,
      clientId,
      rootAccountId
    ).filter(
      (widget: CloudwatchWidget) => widget.properties.metrics.length > 0
    );
  } else {
    return buildExternalWidgets(
      S3_DISCOVERY_METRICS,
      dimensions,
      clientId,
      rootAccountId
    ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
  }
};

export const getDragonStoneMetrics = (
    domainName: string,
    clientId: string,
    rootAccountId: string,
    region: string,
    cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const dimensions = [
    [
      {
        name: ES_METRICS.dimensionNames.CLIENT_ID,
        value: clientId,
      },
      {
        name: ES_METRICS.dimensionNames.DOMAIN_NAME,
        value: domainName,
      },
    ],
  ];
  if (cwDef) {
    return buildWidgets(
        DRAGONSTONE_METRICS,
        dimensions,
        region,
        clientId,
        rootAccountId
    ).filter(
        (widget: CloudwatchWidget) => widget.properties.metrics.length > 0
    );
  } else {
    return buildExternalWidgets(
        DRAGONSTONE_METRICS,
        dimensions,
        clientId,
        rootAccountId
    ).filter((widget: ExternalWidget) => widget.metrics.length > 0);
  }
};

const buildExternalWidgets = (
  cwMetricsSet: MetricDefinitions,
  dimensions: MetricDimension[][],
  clientId: string,
  rootAccountId: string = ''
): ExternalWidget[] => {
  return cwMetricsSet.graphs.map((graph: any) => {
    return {
      title: graph.title.replace(/_/g, ' '),
      metrics: graph.paramSets.reduce((acc: any[], param: any) => {
        const metrics: any[] = [];
        if (graph.mathFunctionParams) {
          graph.mathFunctionParams.forEach((mathFunctionParam) => {
            metrics.push(mathFunctionParam);
          });
        }
        param.metricNames.forEach((metricName: string) => {
          dimensions.forEach((dimension: MetricDimension[]) => {
            if (cwMetricsSet.namespace === 'AWS/ES') {
              metrics.push({
                alias: 'm0',
                namespace: cwMetricsSet.namespace,
                dimensions: dimension,
                account_id: param.isRootAccount ? rootAccountId : clientId,
                metric_name: metricName,
                stats: param.stats,
              });
            } else {
              metrics.push({
                alias: 'm0',
                namespace: cwMetricsSet.namespace,
                dimensions: dimension,
                account_id: rootAccountId,
                metric_name: metricName,
                stats: param.stats,
              });
            }
          });
        });
        return [...acc, ...metrics];
      }, []),
    };
  });
};

const buildWidgets = (
  cwMetricsSet: MetricDefinitions,
  dimensions: MetricDimension[][],
  region: string,
  clientId: string,
  rootAccountId: string
): CloudwatchWidget[] => {
  return cwMetricsSet.graphs.map((graph: any) => {
    return {
      type: 'metric',
      properties: {
        title: graph.title.replace(/_/g, ' '),
        metrics: graph.paramSets.reduce((acc: any[], param: any) => {
          const metrics: any[] = [];
          if (graph.mathFunctionParams) {
            graph.mathFunctionParams.forEach((mathFunctionParam) => {
              metrics.push(mathFunctionParam);
            });
          }
          param.metricNames.forEach((metricName: string) => {
            param.stats.forEach((stat: string) => {
              dimensions.forEach((dimension: MetricDimension[]) => {
                if (cwMetricsSet.namespace === 'AWS/ES') {
                  if (param.isRootAccount) {
                    metrics.push([
                      cwMetricsSet.namespace,
                      metricName.toString(),
                      ...dimension.map((dim) => Object.values(dim)).flat(),
                      {
                        accountId: rootAccountId,
                        region,
                        stat,
                      },
                    ]);
                  }
                } else {
                  metrics.push([
                    cwMetricsSet.namespace,
                    metricName.toString(),
                    ...dimension.map((dim) => Object.values(dim)).flat(),
                    {
                      accountId: rootAccountId,
                      region,
                      stat,
                    },
                  ]);
                }
              });
            });
          });
          return [...acc, ...metrics];
        }, []),
        region,
      },
    };
  });
};

export const isBeaglePoundEpAvailable = (region: string) => {
  return region in BEAGLEPOUND_EP;
};

export const getSifiDashbordLink = (
  domainIdent: string,
  dashboardName: string,
  dashboardId: string,
  region: string
) => {
  const ep: string = `https://search-beaglepound-${BEAGLEPOUND_EP[region]}.${region}.es.amazonaws.com`;
  const filter =
    `('$state':(store:globalState),meta:(alias:!n,controlledBy:'1599128556921',disabled:!f,` +
    `index:'${BEAGLEPOUND_OBJ['perfanalyzer*']}',key:domainIdent,negate:!f,` +
    `params:(query:'${domainIdent}'),type:phrase),` +
    `query:(match_phrase:(domainIdent:'${domainIdent}')))`;
  const url =
    `${ep}/_plugin/kibana/app/kibana?security_tenant=global#/dashboard/${dashboardId}?` +
    `_g=(filters:!(${filter}),time:(from:now-3h,to:now))`;
  return encodeURI(url);
};

export const getBeaglePoundDashbord = (domainIdent: string, region: string) => {
  return Object.entries(BEAGLEPOUND_DASHBOARD_OBJ).map(([k, v]) => ({
    label: k,
    href: getSifiDashbordLink(domainIdent, k, v, region),
    iconType: 'copyClipboard',
    size: 's',
  }));
};

export const getTimzoneOffset = (inputTimezone: string) => {
  let localCurrentDate: any = new Date();
  let inputzoneCurrentDate: any = new Date(
    localCurrentDate.toLocaleString('en-US', { timeZone: inputTimezone })
  );
  let localInputzoneOffsetInMinutes = Math.round(
    (inputzoneCurrentDate - localCurrentDate) / (1000 * 60)
  );
  return Math.round(localInputzoneOffsetInMinutes * 60);
};

export const timezoneOptions = [
  {
    inputDisplay: 'Browser Timezone',
    label: 'Browser Timezone',
    value: 'Browser Timezone',
    text: 0,
    offset: '+0530',
    mins: 330,
  },
  {
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    text: new Date().getTimezoneOffset() * 60,
    offset: '0000',
    mins: 0,
  },
  {
    inputDisplay: 'US/Pacific (PDT)',
    label: 'US/Pacific (PDT)',
    value: 'US/Pacific',
    text: getTimzoneOffset('US/Pacific'),
    offset: '-0700',
    mins: -420,
  },
  {
    inputDisplay: 'America/Phoenix (MDT)',
    label: 'America/Phoenix (MDT)',
    value: 'America/Phoenix',
    text: getTimzoneOffset('America/Phoenix'),
    offset: '-0600',
    mins: -360,
  },
  {
    inputDisplay: 'America/NewYork (EDT)',
    label: 'America/NewYork (EDT)',
    value: 'America/New_York',
    text: getTimzoneOffset('America/New_York'),
    offset: '-0400',
    mins: -240,
  },
  {
    inputDisplay: 'America/Chicago (CDT)',
    label: 'America/Chicago (CDT)',
    value: 'America/Chicago',
    text: getTimzoneOffset('America/Chicago'),
    offset: '-0500',
    mins: -300,
  },
  {
    inputDisplay: 'Asia/Kolkata',
    label: 'Asia/Kolkata',
    value: 'Asia/Kolkata',
    text: getTimzoneOffset('Asia/Kolkata'),
    offset: '+0530',
    mins: 330,
  },
  {
    inputDisplay: 'Asia/Shanghai',
    label: 'Asia/Shanghai',
    value: 'Asia/Shanghai',
    text: getTimzoneOffset('Asia/Shanghai'),
    offset: '+0800',
    mins: 480,
  },
];

export const periodOptions = [
  {
    label: '1s',
    value: '1',
    text: 1,
  },
  {
    label: '1m',
    value: '60',
    text: 60,
  },
  {
    label: '5m',
    value: '300',
    text: 300,
  },
  {
    label: '15m',
    value: '900',
    text: 900,
  },
  {
    label: '1hr',
    value: '3600',
    text: 3600,
  },
  {
    label: '1day',
    value: '86400',
    text: 86400,
  },
];

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const timeDomainDetails = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${timeDomainDetails}`;
  },
  formatUnit: (e, timeDomainDetails) => (1 === timeDomainDetails ? e : e + 's'),
  dateTimeConstraintText:
    'Range must be between 6 - 30 days. Use 24 hour format.',
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
  [
    {
      key: 'previous-6-hours',
      amount: 6,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-1-day',
      amount: 1,
      unit: 'day',
      type: 'relative',
    },
    {
      key: 'previous-3-days',
      amount: 3,
      unit: 'day',
      type: 'relative',
    },
    {
      key: 'previous-1-week',
      amount: 1,
      unit: 'week',
      type: 'relative',
    },
    {
      key: 'previous-3-weeks',
      amount: 3,
      unit: 'week',
      type: 'relative',
    },
  ];

export const getElasticsearchHAProxyMetrics = (
  domainName: string,
  clientId: string,
  instanceIds: string[],
  metricNames: any,
  stats: string[],
  rootAccountId: string,
  region: string,
  cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const dimensions = instanceIds.map((id: string) => [
    {
      name: HA_PROXY_METRIC_DIMENSIONS.INSTANCE_ID,
      value: id,
    },
    {
      name: HA_PROXY_METRIC_DIMENSIONS.CLIENT_ID,
      value: clientId,
    },
    {
      name: HA_PROXY_METRIC_DIMENSIONS.DOMAIN_NAME,
      value: domainName,
    },
  ]);
  const graphs: GraphDefinition[] = metricNames.map((metric) => {
    return {
      title: metric.label,
      paramSets: [
        {
          stats: stats,
          metricNames: [metric.value],
          isRootAccount: true
        },
      ],
    }
  });
  const metrics: MetricDefinitions = {
    namespace: 'AWS/ES',
    dimensionNames: HA_PROXY_METRIC_DIMENSIONS,
    graphs: graphs
  }
  const dimensionChunks = chunkArray(dimensions, 400)
  const haProxyMetrics = buildMetricsForChunks(
    metrics,
    dimensionChunks,
    clientId,
    rootAccountId,
      true,
      region
  );
  return haProxyMetrics;
};

export const getProcessMetrics = (
  domainName: string,
  clientId: string,
  instanceIds: string[],
  metricNames: any,
  stats: string[],
  rootAccountId: string,
  region: string,
  cwDef: boolean = true
): CloudwatchWidget[] | ExternalWidget[] => {
  const dimensions = instanceIds.map((id: string) => [
    {
      name: PROCESS_METRIC_DIMENSIONS.INSTANCE_ID,
      value: id,
    },
    {
      name: PROCESS_METRIC_DIMENSIONS.CLIENT_ID,
      value: clientId,
    },
    {
      name: PROCESS_METRIC_DIMENSIONS.DOMAIN_NAME,
      value: domainName,
    },
  ]);
  const dimensionChunks = chunkArray(dimensions, 400);
  const graphs: GraphDefinition[] = metricNames.map((metric) => {
    return {
      title: metric.label,
      paramSets: [
        {
          stats: stats,
          metricNames: [metric.value],
          isRootAccount: true
        },
      ],
    }
  });
  const metrics: MetricDefinitions = {
    namespace: 'AWS/ES',
    dimensionNames: PROCESS_METRIC_DIMENSIONS,
    graphs: graphs
  }
  const processMetrics = buildMetricsForChunks(
    metrics,
    dimensionChunks,
    clientId,
    rootAccountId,
      true,
      region
  );
  return processMetrics;
};
